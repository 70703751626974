@import "../../variables.scss";
@import "../../mixins.scss";

.home-page-component {
  @include devices(tablet) {
    padding-top: 50px;
  }

  .mobile-toggle-btn {
    width: 50px;
    height: 50px;
    background: url(../../../public/images/icon-menu.svg) center center no-repeat;

    &.menu-active {
      background: url(../../../public/images/icon-menu-close.svg) center center no-repeat;
    }
    display: none;

    @include devices(tablet) {
      display: block;
    }
  }
  .site-nav {
    position: relative;
    align-items: center;

    @include devices(tablet) {
      position: fixed;
      // width: 100%;
      left: 0;
      top: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.95);
      padding: 0 15px;
      z-index: 10;
    }

    .container-nav {
      @include devices(tablet) {
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 300px;
        background-color: rgba(255, 255, 255, 0.95);
        top: 76px;
        left: 0;
        height: 95vh;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        &.menu-active {
          transform: translateX(0);
        }
      }
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        margin: 0 30px;
        font-size: 22px;
        font-weight: 300;

        @include devices(tablet) {
          margin: 20px;
          font-size: 30px;
        }
      }
    }
  }

  .overlayForCloseMenu {
    @include devices(tablet) {
      position: fixed;
      background-color: transparent;
      top: 76px;
      left: 300px;
      right: 0;
      bottom: 0;
    }
  }
  .site-logo {
    display: block;
    width: 200px;
    margin: 50px auto 70px;
  }
  .site-title {
    text-align: center;
    margin: 0px 0 40px;
    font-size: 40px;
    font-weight: 400;
  }

  .lang-nav {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 300;

    @include devices(tablet) {
      right: 15px;
    }

    button {
      background: none;
      margin: 0 5px;

      &.active,
      &:hover {
        font-weight: 400;
      }
    }
  }

  .site-intro {
    text-align: justify;
    margin: 30px 0;
    font-size: 25px;
    line-height: 1.33;
    font-weight: 300;
  }
  .section-title-wrapper {
    position: relative;
    text-align: center;
    padding: 0 50px;
    &:before {
      content: " ";
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: 6px;
      // background: $color-success;
      $color: rgb(99, 169, 61);
      background: linear-gradient(90deg, rgba($color, 0) 0%, rgba($color, 1) 49%, rgba($color, 0) 100%);
    }
    .section-title {
      position: relative;
      display: inline-block;
      text-align: center;
      vertical-align: top;
      margin: 30px 0;
      padding: 0 150px;
      font-size: 60px;      
      line-height: 1.33;
      font-weight: 600;
      background: white;

      @media screen and (max-width: 800px) {
        font-size: 55px;
        padding: 0 50px;
      }
      @media screen and (max-width: 420px) {
        font-size: 40px;
        padding: 0 20px;
      }      
    }
  }
  .top-level-section {
    // padding: 50px 0;
  }
  .project-section {
    padding: 50px 0;
  }
  .project-title {
    text-align: center;
    margin: 30px 0 50px;
    font-size: 50px;
    line-height: 1.33;
    font-weight: 300;
  }
  .project-intro {
    text-align: justify;
    margin: 30px 0;
    font-size: 24px;
    line-height: 1.33;
    font-weight: 300;

    ul {
      padding-left: 23px;

      li {
        margin-bottom: 15px;
      }
    }
  }
  .project-count {
    text-align: justify;
    margin: 30px 0;
    font-size: 24px;
    line-height: 1.33;
    font-weight: 300;
  }

  .fundraising-section {
    padding: 10px 0;
    background: #faf9f2;
    border-top: 2px solid rgba(0, 0, 0, 0.135);
  }
  .fundraising-title {
    text-align: center;
    margin: 50px 0 70px;
    font-size: 44px;
    line-height: 1.33;
    font-weight: 300;
  }

  .fundraising-item-section {
    display: flex;
    width: 100%;
    padding: 0 0 70px;

    @include devices(tablet) {
      flex-direction: column;
      align-items: center;
    }

    .fundraising-item-viz {
      width: 400px;
      height: 400px;
      @media screen and (max-width: 420px) {
        width: 300px;
        height: 300px;
      }
      position: relative;
      border-radius: 50%;
      border: 10px solid #e0e0e0;
      box-shadow: 0px 0px 4px 6px rgba(0, 0, 0, 0.03);
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;

      @include devices(tablet) {
        margin-bottom: 50px;
      }

      img {
        display: block;
        height: 381px;

        @media screen and (max-width: 420px) {
          height: 281px;
        }
      }

      .fundraising-item-viz-counter {
        position: absolute;
        width: 100px;
        height: 100px;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        background: #ffffff;
        border: 7px solid #e0e0e0;
        box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.03);
        font-weight: 500;
        font-size: 38px;
        line-height: 80px;
        text-align: center;

        @media screen and (max-width: 420px) {
          height: 70px;
          width: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .fundraising-item-text-col {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;

      .fundraising-item-text-wrapper {
        display: flex;
        // flex: 1;
        max-width: 350px;
        justify-content: center;

        section {
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: start;

          .line-vertical {
            background: grey;
            width: 1px;
            flex: 1;
          }
          .line-horizontal {
            background: grey;
            width: 7px;
            height: 1px;
          }

          &:last-child {
            align-items: end;
          }
        }
        .fundraising-item-text {
          padding: 0 30px;
          text-align: center;
          font-size: 35px;
          font-weight: 300;
          line-height: 1.33;

          a {
            color: inherit;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .fundraising-connector {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 50px 0 60px 200px;

    @include devices(tablet) {
      padding: 50px 0;
      margin: 0 auto;
      text-align: center;
      flex-direction: column;
      flex-direction: column-reverse;
    }

    img {
      display: block;
    }
    .fundraising-connector-text {
      max-width: 400px;
      margin-left: 20px;
      transform: translate(0, -5px);
      font-size: 20px;
      line-height: 1.33;

      @include devices(tablet) {
        margin-left: 0;
        margin-bottom: 40px;
      }

      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
  .payment-section {
    border: 1px solid rgba(black, 0.2);
    border-radius: 4px;
    padding: 25px 30px;
    * {
      word-break: break-word;
    }
  }
  .payment-details {
    padding: 70px 0;
    background: #faf9f2;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);

    @include devices(tablet) {
      .grid-container-component {
        flex-direction: column;
        align-items: center;
      }
      .grid-col-component {
        width: 100%;
        max-width: 400px;
        margin-bottom: 20px;
      }
    }
  }
  .payment-details-section {
    margin-bottom: 40px;
  }
  .payment-details-title {
    text-align: center;
    margin: 10px 0 60px;
    font-size: 40px;
    line-height: 1.33;
    font-weight: 300;
  }
  .payment-details-subtitle {
    margin-bottom: 13px;
    font-size: 24px;
    line-height: 1.33;
    text-transform: uppercase;
  }
  .payment-details-currency-title {
    margin-bottom: 4px;
    font-size: 22px;
    line-height: 1.33;
  }
  .payment-details-currency-vallet {
    margin-bottom: 25px;
    font-size: 22px;
    line-height: 1.33;
    color: $color-text-link;
    word-break: break-all;
  }
  .contacts-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 34px;

    .contacts-row {
      display: block;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
    }

    .go-home {
      padding: 5px 20px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      background-color: rgba(222, 219, 187, 0.15);
      transition: all 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
      &:hover, &:focus {
        background-color: #dedbbb;
        text-decoration: none;
      }
    }

    @include devices(tablet) {
      font-size: 20px;
    }

    a {
      color: inherit;
    }
  }

  .qr-code-wrapper {
    width: 100%;
    padding: 0 30px;

    .qr-code-title {
      display: block;
      font-size: 30px;
      text-align: center;
      margin: 0 0 30px;
      color: black;
    }
    .qr-code-image-wrapper {
      background: white;
      padding: 40px;
      border: 2px solid black;
      border-radius: 40px;

      &:hover {
        box-shadow: 0 0 0px 5px rgba(0, 195, 55, 0.25);
        cursor: pointer;
      }

      .qr-code {
        display: block;
        width: 100%;
      }
    }
    .qr-card-name {
      margin: 20px 0 0;
      font-size: 30px;
      text-align: center;
    }
  }
  .payment-btn-section {
    margin: 0 auto;

    @include devices(tablet) {
      .grid-col-component {
        margin-bottom: 20px;
      }
    }
  }
  .payment-btn {
    display: flex;
    background: white;
    border-radius: 7px;
    height: 80px;
    padding: 0 20px;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid rgba(black, 0.25);

    @include devices(tablet) {
      flex-direction: column;
      padding: 20px;
      height: auto;
    }

    .payment-btn-icon {
      display: flex;
      margin-right: 15px;

      img {
        display: block;
        height: 30px;
        margin-right: 15px;
      }
    }

    .payment-btn-title {
      color: rgba(black, 0.75);
      font-size: 20px;
    }
    .payment-btn-email {
      flex: 1;
      text-align: right;
      font-size: 18px;

      @include devices(tablet) {
        margin-top: 10px;
      }
    }
  }
}
